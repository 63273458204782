@font-face {
  font-family: 'Europa';
  src: url('/fonts/Europa/Europa-Regular.woff2') format('woff2'),
    url('/fonts/Europa/Europa-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Europa';
  src: url('/fonts/Europa/Europa-Bold.woff2') format('woff2'),
    url('/fonts/Europa/Europa-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Europa';
  src: url('/fonts/Europa/Europa-Italic.woff2') format('woff2'),
    url('/fonts/Europa/Europa-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Europa';
  src: url('/fonts/Europa/Europa-BoldItalic.woff2') format('woff2'),
    url('/fonts/Europa/Europa-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}



:root {
  --border-color: #e6e6e6;
  --primary-color: #4E7A92;
  --primary-color-border: #9bafbc;
}

.App {
  text-align: center;
}

.internal {

  font-family: 'Europa';

  h1,
  h2,
  h3,
  h4,
  h5,
  button {
    font-family: 'Europa';
    font-weight: bold;
  }

  input,
  textarea {
    font-family: Europa;
  }
}

.other {
  --primary-color: #000000;
  --primary-color-border: #666;
  font-family: 'Arial';

  h1,
  h2,
  h3,
  h4,
  h5,
  button {
    font-family: 'Arial';
  }

  input,
  textarea {
    font-family: Arial;
  }
}

header {
  height: 30.438px;

  p {
    font-size: 14px;

    a {
      color: black;
    }
  }
}

.logo img {
  // max-width: 200px;
  // max-height: 75px;
  max-height: 40px;
  max-width: 430px;

  @media screen and (max-width: 767px) {
    max-width: 200px;
  }
}

.internal {
  .logo img {
    // max-width: 350px;
  }
}

.loading_wrap {
  overflow: hidden;
}

.icon-loading {
  width: 64px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

header {
  padding: 30px 10px;
  display: flex;
  max-width: 1100px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #979797;
}

header .logo {
  text-align: left;
}

header .logo img {
  // width: 50%;
  display: block;

}

header .back {
  color: #000000;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
}

.number {
  font-size: 20px;
}

.app {
  display: flex;
  max-width: 1100px;
  justify-content: center;
  margin: 0px auto;
  padding: 0 10px;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000000;
  // font-family: "Europa-Bold";
  font-weight: 700;
  font-size: 40px;
  font-weight: bold;
  line-height: 48px;
  max-width: 1100px;
  margin: 10px auto 0px auto;
  padding: 0 10px 20px 10px;

  @media screen and (max-width: 992px) {}

  @media screen and (max-width: 767px) {
    font-size: 28px;
    line-height: 32px;
  }

  .title-header {
    display: flex;
    align-items: center;

    @media screen and (max-width: 992px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      text-align: left;
    }

    @media screen and (max-width: 486px) {
      display: none;
    }

    .small {
      color: #000000;
      // font-family: "Europa";
      font-size: 18px;
      padding-top: 8px;
      line-height: 22px;
      margin-left: 30px;

      @media screen and (max-width: 992px) {
        margin-left: 0;
      }
    }

    &-secondary {
      color: #000000;
      // font-family: "Europa-Bold";
      font-weight: 700;
      padding-top: 8px;
      font-size: 16px;
      font-weight: bold;
      line-height: 23px;
      display: flex;
      align-items: center;

      @media screen and (max-width: 486px) {
        font-size: 14px;
      }

      .icon {
        width: 35px;
        margin-right: 10px;

        &-delivery {
          width: 35px;
          margin-left: 40px;
          margin-right: 5px;
        }
      }
    }
  }
}

.configurator {
  width: 51%;
}

.steps {
  text-align: left;
}

.step {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  padding-bottom: 10px;
  font-size: 14px;
  border-bottom: 1px solid var(--border-color);

  .heading {
    display: flex;
    align-items: center;

    // margin-left: 50px;
    @media screen and (max-width: 992px) {
      margin-left: 0;
    }
  }

  .index {
    width: 40px;
    min-width: 40px;
    text-align: left;
  }

  .name {
    color: #333333;

    font-size: 24px;
    font-weight: bold;
    line-height: 29px;
  }

  .input {
    margin-left: 50px;
    width: calc(100% - 50px);

    @media screen and (max-width: 992px) {
      margin-left: 0;
      width: calc(100% - 0px);
    }

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
}

p.step {
  text-align: left;
  border-bottom: 0;
  line-height: 140%;
}

.step-name {
  display: flex;
  align-items: center;

  // margin-left: 50px;
  @media screen and (max-width: 992px) {
    margin-left: 0;

  }
}

.index {

  color: #d9d9d9;

  font-size: 30px;
  font-weight: bold;
  line-height: 36px;
  text-align: center;
  margin-right: 10px;
}


.preview {
  padding: 0 0 0 30px;
  box-sizing: border-box;
  width: 49%;
  max-width: 658px;

}

.preview img {
  width: 100%;
}

.items {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

}

// .injected-svg {
//   defs {
//     image {
//       x: 0 !important;
//       y: 0 !important;
//     }
//   }
// }
.item {
  width: 110px;
  box-sizing: border-box;
  // margin: 10px 5px;
  padding: 10px 5px;
  border: 2px solid transparent;
  position: relative;

  @media screen and (max-width: 992px) {
    width: 150px;
    margin: 0 auto;
  }

  @media screen and (max-width: 776px) {
    width: 110px;
    margin: 0 auto;
    padding: 10px 5px;
  }

  @media screen and (min-width: 420px) {
    &::before {
      content: '';
      height: 20px;
      width: 20px;
      background-color: var(--primary-color);
      position: absolute;
      top: -1px;
      right: -1px;
      display: none;
    }

    &::after {
      content: "+";
      position: absolute;
      top: -3px;
      right: 3px;
      font-weight: 200;
      color: white;
      // display: none;
      line-height: 21px;
      font-size: 21px;
      display: none;
    }
  }

  &:hover {
    @media screen and (min-width: 420px) {
      border: 2px solid var(--primary-color);
      cursor: pointer;

      &::before,
      &::after {
        display: block;
      }
    }
  }

}

.menu {
  a {
    margin-left: 20px;
  }
}

.item.used {
  border: 2px solid var(--primary-color);
  position: relative;

  // pointer-events: none;
  &::before {
    content: '';
    height: 20px;
    width: 20px;
    background-color: var(--primary-color);
    position: absolute;
    top: -1px;
    right: -1px;
    display: block;
  }

  &::after {
    content: '';
    position: absolute;
    background-image: url('/static/images/checkmark.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 12px;
    height: 12px;
    top: 4px;
    right: 3px;
    font-weight: 400;
    // color: white;
    // display: none;
    line-height: 12px;
    font-size: 12px;
    display: block !important;
  }

  &.visited {
    @media screen and (min-width: 420px) {
      &:hover {
        // border-color: #B80B0B;
        border: 2px solid #B80B0B;

        &::after {
          content: "X";
          background: none;
          top: 2px;
          right: 0px;
          display: block;
        }

        &::before {
          content: '';
          background-color: #B80B0B;
        }
      }
    }
  }

}

.item.hidden {
  opacity: 0.5;
  pointer-events: none;
}

.item img {
  max-width: 63px;
  display: block;
  margin: 0 auto;
  // border-radius: 50%;
}

.item p {
  font-size: 10px;
  text-align: center;
  margin-bottom: 0;
}

.type.pattern .item img {
  border-radius: 0%;
}

.type:not(.pattern) {
  padding-top: 20px;
  position: relative;
  width: 100%;
}

@media screen and (max-width: 992px) {
  .type:not(.pattern) {
    width: 100%;
  }
}

.type:not(.pattern):after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #ccc;
}

.type.pattern h4 {
  width: 95%;
}

.type.pattern {
  .items {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));

    @media screen and (max-width: 930px) {
      display: grid;
      grid-template-columns: 25% 25% 25% 25%;
      justify-items: center;
    }

    @media screen and (max-width: 768px) {
      display: grid;
      grid-template-columns: 33% 33% 33%;
    }

    @media screen and (max-width: 480px) {
      display: grid;
      grid-template-columns: 33% 33% 33%;
    }
  }

  .item {
    width: 180px;
    padding: 10px 0;

    @media screen and (max-width: 929px) {
      width: 100%
    }

    @media screen and (max-width: 768px) {
      width: 100%
    }

    @media screen and (max-width: 420px) {
      width: 100%;
      padding: 5px 0;
    }

    img {
      max-width: 150px;

      @media screen and (max-width: 768px) {
        max-width: 120px;
      }

      @media screen and (max-width: 420px) {
        max-width: 100px;
      }
    }

  }
}

.type:not(.pattern) h4 {
  margin-top: 0;
}

.type:not(.pattern) h4 span {
  font-size: 12px;
  font-weight: 400;
  margin-left: 30px;
}

.type:not(.pattern) .item {
  width: 110px;
  margin: 5px;

  @media screen and (max-width: 768px) {
    width: 70px;
    margin: 0;
  }
}

.type.blocked {
  opacity: 0.5;
  pointer-events: none;
  //
}

.type.blocked-type {
  display: none;
}

/* 
  INPUTS
*/
.inputs-group,
.step.grout {
  width: calc(100% + 1px);

  .input {
    border-bottom: 0;
  }

  &.locked {
    position: relative;
    padding-top: 15px;

    cursor: not-allowed;

    .input,
    .percantage,
    .add-button {
      pointer-events: none;
      opacity: 0.5;
    }

    &:after {
      content: "Changes not allowed when In Room view";
      position: absolute;
      top: 0;
      left: 0;
      font-size: 12px;
      font-weight: 700;
      // display: none;
    }

    &:hover {
      &::after {
        color: darkred;
      }
    }
  }


}

.step.grout.locked {
  &:after {
    display: none;
  }
}

.input {
  cursor: pointer;
}


/* Pattern */

.step-name {
  min-width: 200px;
}

.text.empty {
  color: #696969;
  font-size: 14px;
  line-height: 18px;
}

.steps.no-pattern .step:not(.pattern) {
  opacity: 0.5;
  cursor: not-allowed;
}

.steps.no-pattern .step:not(.pattern) .material-select,
.steps.no-pattern .step:not(.pattern) .select-grout__control {
  pointer-events: none;
}

/* No pattern */

.add {
  border: 1px solid #e6e6e6;
  border-left: 0;
  padding: 10px;
  // background: #eee;
  display: flex;
  align-items: center;
  min-width: 20px;
}

.add svg {
  width: 0.875em !important;
  margin: 0 auto;
}

.buttons-randomize {
  display: flex;

}

.bottom-note {
  text-align: left;
  margin: 40px 0;
  color: #000000;
  font-size: 12px;
  line-height: 15px;

  @media screen and (max-width: 767px) {
    margin: 50px 0 20px 0;
    padding: 0 5px;
  }
}

.inputs-group .add-button {
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: white;
  width: 90px;
  margin: 10px 0 0 50px;
  text-align: center;

  @media screen and (max-width: 992px) {
    margin: 10px 0;
  }

  @media screen and (max-width: 768px) {
    margin: 10px 0;
  }

  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

.inputs-group .add-button.disabled {
  cursor: not-allowed;
  background-color: #D0CECE;
  position: relative;
  color: white;

  >div {
    pointer-events: none;
  }

  &:hover {
    opacity: 1;

    &:before {
      content: 'You have reached the maximum of 10 materials for your design.';
      position: absolute;
      bottom: 30px;
      left: 10px;
      color: #AB2A22;
      width: 100%;
      font-size: 12px;
      padding: 5px;
      background: rgba(#F4E7E6, 1);
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 25px;
      left: 60%;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;

      border-top: 5px solid #F4E7E6;
    }
  }

  &-chip {
    &:hover {
      &:before {
        content: 'You have reached the maximum of materials for zone';
      }
    }
  }
}


svg.materials {
  display: block;
  height: 0;
  width: 0;
  border: 0;
}

.button__quote {
  display: block;
  background: var(--primary-color);
  /* border: 1px solid #000; */
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;

  span {
    display: flex;
    align-items: center;

    div {

      // margin-right: 5px;
      svg {}
    }
  }

  &--blue {
    background: white;
    border: 2px solid var(--primary-color);
    height: 45px;
    width: calc(100% - 4px);
      color: var(--primary-color);

      font-size: 16px;
      font-weight: bold;
      line-height: 19px;


      &:hover {
        background: var(--primary-color) !important;
        color: white;
      }
    

  }

  
}

.button__quote:hover {
  background: #333;
  /* color: #000; */
}

.inputs__wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;

  >p {
    margin-top: 20px !important;
    margin-bottom: 0px !important;
    text-align: left;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 140% !important;

    a {
      text-decoration: underline !important;
    }
  }
}

.step.buttons .input-btn {
  width: 100%;
  /* display: block; */

  &:nth-child(2) {
    margin-top: 15px;
  }
}

.step.buttons {
  // border-bottom: 0;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  padding: 5px 0 20px 0;

  p {
    font-size: 14px;
    font-weight: 600;
    margin-top: 30px;
  }

  a {
    color: var(--primary-color);
  }

  .step-name {
    align-items: flex-start;
    min-width: 170px;

    @media screen and (max-width: 1025px) {
      min-width: 160px;
    }

    .name {
      text-align: left;
    }
  }
}

.step:last-child {
  border-top: 1px solid #ccc;
  padding-top: 20px;
}

.input-btn,
.input-btn-groups {
  &.blocked {
    cursor: not-allowed;
    opacity: 0.5;

    .button {
      pointer-events: none;
    }
  }
}

.input-btn-groups {
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;

}

.input-btn-groups .button {
  width: 47%;
  background: white;
  border: 2px solid var(--primary-color);
  height: 45px;

  span {
    display: flex;
    color: var(--primary-color);

    font-size: 16px;
    font-weight: bold;
    line-height: 19px;

    .svg>div {
      display: flex;
      align-items: center;
    }

    svg {
      margin-right: 8px;
      width: 16px;

      * {
        fill: var(--primary-color);
      }
    }
  }

}

.input-btn-groups .button:hover {
  background: var(--primary-color);

  span {
    color: white;
  }
}

.input-btn-groups .button:hover svg * {
  fill: #fff;
}

.ReactModal__Body--open {
  overflow-y: hidden;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  top: 160px;
  background-color: transparent;
}

.ReactModal__Overlay {
  z-index: 1000;
  background: rgba(0, 0, 0, 0.8) !important;
}

.ReactModal__Content.ReactModal__Content--after-open {
  width: 65vw;
  height: fit-content;
  margin: 0 auto;
  /* margin-top: 100px; */

}

.input {
  width: 100%;
}

.css-yk16xz-control {
  border-radius: 0 !important;
  outline: none !important;
  border-color: var(--border-color) !important;
}

.css-tlfecz-indicatorContainer {
  background: transparent !important;
  border-left: 1px solid #e6e6e6;
  width: 100%;
}

.css-tlfecz-indicatorContainer svg {
  fill: #000;
}

.css-1okebmr-indicatorSeparator {
  display: none;
}

.select-grout {
  /* margin: 2px 0 0; */
  width: 100%;

  &__option {
    text-align: left;
    padding: 5px 7px !important;
  }

  &.materials {
    display: none;
    width: calc(100vw - 20px);

    &__option {
      padding: 10px 7px !important;
    }

    @media screen and (max-width: 767px) {
      display: block;
    }
  }
}

.links {
  .select-grout {
    width: 100%;

    &__option {
      padding: 10px 7px !important;
    }
  }
}

.select-grout__option--is-selected {
  background-color: #eee !important;
  color: #000 !important;
}

.select-grout__control {
  margin-top: 5px;
  border: 1px solid #e6e6e6 !important;
  border-radius: 0 !important;
  height: 50px;

  @media screen and (max-width: 992px) {
    height: 43px;
    margin-top: 0;
  }

  @media screen and (max-width: 767px) {
    height: 38px;

    margin-top: 5px;
  }
}

.select-grout__indicators {
  max-height: 100%;
}

.select-grout__indicator {
  height: 100%;
  max-width: 37px;
}

.select-grout__indicator svg {
  height: 24px;
}

.select-grout__control--is-focused {
  outline: none;
  box-shadow: none !important;
  border: 1px solid #ccc !important;
}

.select-grout__option--is-focused {
  background-color: transparent !important;
}

.step.grout {
  @media screen and (max-width: 992x) {

    .step-name {
      padding-bottom: 5px;
    }
  }

  @media screen and (max-width: 768px) {
    .select-grout {
      width: calc(100% - 0px);
      // left: 50px;
    }

    .step-name {
      padding-bottom: 0;
    }
  }
}

.step.grout .select-grout__control {
  height: 40px;
}

.select-grout__value-container {
  padding: 4px 8px !important;
  height: 100%;
  max-height: 100%;
}

.select-grout__value-container--has-value {
  padding: 4px 2px !important;
}

.select-grout__menu {
  border-radius: 0 !important;
  /* border-color: var(--border-color) !important; */
  margin-top: -1px !important;
  display: block;
  z-index: 9999 !important;
  box-shadow: none !important;
  border: 1px solid var(--border-color) !important;
  width: calc(100%) !important;
}

.select-grout__menu-list {
  border-radius: 0;
  z-index: 9999 !important;
}

#zone-1 circle,
#zone-1 rect,
#zone-1 polygon,
#zone-1 path,
#zone-1 ellipse {
  fill: #ccc;
  position: relative;

  &::before {
    content: '1';
    position: absolute;
    top: 0;
    left: 0;
  }
}

#zone-2 circle,
#zone-2 rect,
#zone-2 polygon,
#zone-2 path,
#zone-2 ellipse {
  fill: #999;
}

#zone-3 circle,
#zone-3 rect,
#zone-3 polygon,
#zone-3 path,
#zone-3 ellipse {
  fill: #666;
}

#zone-4 circle,
#zone-4 rect,
#zone-4 polygon,
#zone-4 path,
#zone-4 ellipse {
  fill: #333;
}


.preview__buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 50px;
  z-index: 1000;
  min-width: 510px;

  @media screen and (max-width: 1056px) {
    min-width: 100%;
  }

  @media screen and (max-width: 992px) {
    bottom: 50px;
  }

  @media screen and (max-width: 992px) {
    // bottom: 40px;
    min-width: 100%;
    // width: 100%;%;
  }

  @media screen and (max-width: 983px) {
    bottom: 65px;
  }

  @media screen and(max-width: 767px) {
    // width: 100%;
    width: 80%;
    min-width: auto;
    margin: 0 10%;
    bottom: 60px;
  }

  @media screen and(max-width: 480px) {
    // width: 100%;
    width: calc(100% - 2px);
    left: 1px;
    margin: 0;
    bottom: 60px;
  }

  @media screen and(max-width: 333px) {
    // width: 100%;
    width: calc(100% - 2px);
    left: 1px;
    margin: 0;
    bottom: 75px;
  }
}

.preview__button {
  flex: 1;
  padding: 8px 10px;
  border: 1px solid var(--border-color);
  background: white;
  border-top: 0;
  color: #000000;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  cursor: pointer;

  &.blocked {
    display: none;
  }
}

.preview__button:hover {
  background: #ccc;
}

.preview__button.selected {
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: white;
}

.preview__button:first-child {
  border-right: 0;
}

.preview__button:last-child {
  border-left: 0;
  /* cursor: not-allowed; */
}

.close__button {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hidden-full {
  display: none;
}

.hide {
  /* display: none; */
  /* height: 0; */
  /* max-height: 0; */
}

.imagination-logo {
  padding: 30px 0;

  img {
    width: 35%;
  }
}

@media screen and (max-width: 420px) {
  .step.pattern .heading {
    max-width: 80%;
  }

  /* .app {
    display: flex;
    flex-direction: column-reverse;
  }
  .configurator {
    width: 100%;
    padding-left: 0;
  }
  .preview {
    width: 100%;
    padding-left: 0;
  } */
  /* .step:not(.pattern) .inputs-group .input {
    width: 90%;
  }
  .step:not(.pattern) .input {
    width: calc(92% - 60px);
    margin-right: 8%;
  } */
}

@media screen and (max-width: 992px) {
  .step:not(.pattern) {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {

  // .step.pattern .input {
  //   // width: 45%;
  //   margin-left: 0;
  //   padding-left: 5px;
  //   flex: 0 0 157px;
  //   max-width: 55%;
  // }
  // .step.pattern {
  // 	justify-content: space-between;
  // }
  // .step.pattern .heading {
  //   min-width: auto;
  //   flex: 1 1 40%;
  // }
  .step .heading {
    padding-top: 0;
  }


  .step:not(.pattern) .step-name,
  .step:not(.pattern) .heading {
    // margin-bottom: 20px;
    margin-bottom: 10px;
  }

  .step:not(.pattern) .inputs-group {
    align-self: flex-end;
    /* width: calc(100% - 50px); */
    width: 100%;

    @media screen and (max-width: 768px) {
      width: calc(100% - 0px);
    }
  }

  .step:not(.pattern) .inputs-group .input {
    width: 100%;
  }

  .step:not(.pattern) .input {
    /* width: calc(100% - 50px); */
    width: 100%;

    align-self: flex-end;
  }

  .step .inputs-group .material-select {
    margin-bottom: 0;
  }

  .step .inputs-group {
    .input {
      &+.input {
        margin-top: 5px;
      }
    }
  }

  .percentages-wrapper {
    margin-top: 10px;
  }

  .type:not(.pattern):after {
    width: calc(100% - 40px);
  }

  .ReactModal__Content {
    inset: 0 !important;
    width: calc(100vw - 40px) !important;
    height: auto;
    margin-top: 0 !important;
    border-radius: 0 !important;
    left: 0 !important;
    top: 0 !important;
    bottom: auto !important;
    transform: translate(0, 0) !important;
    right: auto !important;
    // max-height: 100vh !important;
    height: calc(100vh - 90px) !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    border: none !important;
    padding-bottom: 90px !important;
  }

  .ReactModal__Content.ReactModal__Content--after-open {
    height: auto;
  }

  /* .configurator {
    width: 50%;
  }
  .preview {
    width: 50%;
    max-width: 100%;
  } */

  .name {
    width: 80%;
  }
}

.ReactModal__Content {
  border-radius: 0 !important;
}

@media screen and (max-width: 987px) {
  .configurator {
    width: 51%
  }

  .preview {
    width: 49%;
  }

  .preview__wrapper {
    min-width: auto;
    // height: 330px;
  }
}

@media screen and (max-width: 767px) {
  .inputs__wrapper {
    /* width: 90%; */
    width: calc(100% - 50px);
    margin-left: 50px;

  }

  .app {
    display: flex;
    flex-direction: column-reverse;
  }

  .configurator {
    width: 100%;
    padding-left: 0;
  }

  .preview {
    width: 100%;
    padding-left: 0;
    margin: 0 auto 40px;

    @media screen and (max-width: 767px) {
      margin: 0 auto 0px;
    }

    .preview__wrapper {
      min-width: auto;
      // height: 500px;
      width: 80%;
      margin: 0 auto;
    }

    .svg__wrapper.single {
      width: 100%;
      min-width: auto;
      // height: 360px;
    }

    .svg__wrapper.kitchen {
      background: #fff;

      // height: 450px;
      .kitchen-wrapper {
        // margin-top: -120px;
        // left: -60px;
        // position: relative;
        // transform: scale(0.8);
        // @media screen and (max-width: 767px) {
        //   margin-top: 0px;
        //   left: 0px;
        //   position: relative;
        //   transform: scale(1.05);
        // }
      }
    }
  }

  .inputs__wrapper {
    /* width: 90%; */
    width: 100%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 480px) {
  .preview {

    .preview__wrapper {
      min-width: auto;
      // height: 360px;
      width: calc(100% - 2px);
      margin: 0 auto;
    }
  }
}

.App.rectangle,
.App.other {
  font-family: 'Arial';
  line-height: 1;

  .button.button__quote {
    // border-radius: 50px;
  }

  .step .index {
    // border-radius: 50%;
  }
}


.placeholder {
  margin-top: 15px;

  h5 {
    display: none;
    font-size: 18px;
    text-align: left;
    margin: 0px 0;

    @media screen and (max-width: 767px) {
      display: block;
    }
  }

  img {
    display: block;

    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}

.App {
  .no_brand {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  >.loading {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &::before {
      display: none;
    }
  }
}

.patterns-modal {
  .ReactModal__Content {
    width: calc(90vw - 40px) !important;
    // max-width: 869px;
    top: 50% !important;
    left: 50% !important;
    bottom: auto;
    width: 75vw;
    height: auto;
    margin-top: 0;
    transform: translate(-50%, -50%) !important;
    max-height: 90vh;
    height: fit-content !important;

    @media screen and (max-width: 768px) {
      width: calc(100vw - 40px) !important;
    }
  }
}